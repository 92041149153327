import React from 'react';
import FadeIn from 'react-fade-in';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Row, Col, Input, Form, Typography } from 'antd';
import { useFormik } from 'formik';
import { validator } from './validator';

import Logo from '../../static/images/Logo.png';

const View = ({ initialValues, onSubmit }) => {
  const handleSubmit = async (formValues, action) => {
    await onSubmit(formValues, action);
  };
  const formik = useFormik({
    initialValues,
    validationSchema: validator,
    onSubmit: handleSubmit,
  });
  const { handleChange, handleBlur, values, isSubmitting, touched, errors } = formik;

  return (
    <Row style={{ minHeight: '100vh' }}>
      <Col lg={6} flex="auto" style={{ padding: 50 }}>
        <FadeIn>
          <div className="text-align-center">
            <img src={Logo} style={{ marginBottom: 64 }} alt="FishGo Dasboard Logo" />
            <Typography.Title style={{ fontWeight: 300 }}>Admin Panel</Typography.Title>
            <Form layout="vertical" onFinish={formik.handleSubmit} colon={false}>
              <Form.Item
                label="Username"
                required
                hasFeedback
                validateStatus={errors.username && touched.username && 'error'}
                help={errors.username && touched.username}
                colon={false}
              >
                <Input name="username" prefix={<UserOutlined />} onChange={handleChange} onBlur={handleBlur} value={values.username} placeholder="johndoe" />
              </Form.Item>
              <Form.Item
                label="Password"
                required
                hasFeedback
                validateStatus={errors.password && touched.password && 'error'}
                help={errors.password && touched.password}
                colon={false}
              >
                <Input
                  name="password"
                  type="password"
                  prefix={<LockOutlined />}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  placeholder="********"
                />
              </Form.Item>
              <Button htmlType="submit" type="primary" block loading={isSubmitting}>
                Log In
              </Button>
            </Form>
          </div>
        </FadeIn>
      </Col>
      <Col lg={18} flex="auto" className="background-image__login"></Col>
    </Row>
  );
};

export default View;
