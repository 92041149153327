import React from 'react';
import { notification } from 'antd';
import { useAuth } from '../../contexts/AuthContext';
import View from './view';

const Index = () => {
  const { signIn } = useAuth();
  const initialValues = {
    username: '',
    password: '',
  };

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const signedUser = await signIn(JSON.stringify(values));
      if (!signedUser.success) {
        setSubmitting(false);
        notification.error({
          message: 'Error',
          description: 'Invalid Username/Password.',
        });
      } else {
        notification.success({
          message: 'Success',
          description: 'Successfully logged in.',
        });
      }
    } catch (e) {
      notification.error({
        message: 'Error',
        description: `Unable to log in because of ${e.message}`,
      });
    }
  };

  return <View initialValues={initialValues} onSubmit={onSubmit} />;
};

export default Index;
