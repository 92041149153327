export const generateQuery = (query) => {
  const { fields, search, page, limit, filters, sort } = query;

  // Generate fields query from array
  let fieldQuery = '';
  if (typeof fields !== 'undefined' && Array.isArray(fields)) {
    if (fields.length > 1) {
      fieldQuery = fields.join(',');
    } else {
      const [field] = fields;
      fieldQuery = field;
    }
  }

  // Generate sort query from object
  let sortQuery = '&sort=';
  if (sort) {
    if (sort.length > 1) {
      sort.forEach((value, index, array) => {
        sortQuery += `${value[0]}:${value[1]}`;
        if (index !== array.length - 1) {
          sortQuery += ';';
        }
      });
    } else {
      sortQuery += `${sort[0][0]}:${sort[0][1]}`;
    }
  }

  // Generate filters query
  let filtersQuery = '&filters=';
  if (typeof filters !== 'undefined' && Array.isArray(filters)) {
    filters.forEach((filter, index) => {
      filtersQuery += `${filter}`;
      if (index !== filters.length - 1) {
        filtersQuery += ';';
      }
    });
  }

  // Generate search query from input
  let searchQuery = '&search=';
  if (search) {
    searchQuery += `${search}`;
  }

  // Generate page query
  let pageQuery = '&page=';
  if (page) {
    pageQuery += `${page}`;
  }

  // Generate limit query
  let limitQuery = '&limit=';
  if (limit) {
    limitQuery += `${limit}`;
  }

  const result = `fields=${fieldQuery}${sortQuery}${filtersQuery}${searchQuery}${pageQuery}${limitQuery}`;
  return result;
};
