import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

export default function Loader() {
  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  const loaderStyle = {
    fontSize: '24px',
  };

  return (
    <div style={divStyle}>
      <LoadingOutlined spin style={loaderStyle} />
    </div>
  );
}

export function BigLoader() {
  const divStyle = {
    minHeight: '100vh',
    minWidth: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  const loaderStyle = {
    fontSize: '128px',
  };

  return (
    <div style={divStyle}>
      <LoadingOutlined spin style={loaderStyle} />
    </div>
  );
}

export function ComponentLoader() {
  const divStyle = {
    minHeight: '100%',
    minWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  const loaderStyle = {
    fontSize: '64px',
  };

  return (
    <div style={divStyle}>
      <LoadingOutlined spin style={loaderStyle} />
    </div>
  );
}
