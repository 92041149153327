const toCapitalCase = (string) => {
  const splitted = string.split(' ');
  const capitalized = splitted.map((word) => {
    return `${word.substr(0, 1).toUpperCase()}${word.slice(1, word.length)}`;
  });

  const joined = capitalized.join(' ');
  return joined;
};

export default toCapitalCase;
