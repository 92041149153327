import axios from 'axios';
import { generateQuery } from './utils';

/**
 * API without token
 * @param: endpoint, withToken, options, headers
 * @type: string, bool, object, object
 */
// Login
export const postAdminLogin = (body) => {
  return fetchFrom('/v2/admin/login', false, { method: 'POST', body }, { 'Content-Type': 'application/json' });
};

/**
 * API with Token
 * @param: endpoint, withToken, options, headers
 * @type: string, bool, object, object
 */

// Auth
export const authenticate = () => {
  return fetchFrom('/v2/authenticate', true);
};

// Admin
export const getAdmins = async (query) => {
  if (query !== undefined) {
    const params = await generateQuery(query);
    return fetchFrom(`/v2/admin?${params}`, true);
  }

  return fetchFrom('/v2/admin', true);
};
export const getAdminByID = (id) => {
  return fetchFrom(`/v2/admin/${id}`, true);
};

export const postAdmin = (body) => {
  return fetchFrom('/v2/admin', true, { method: 'POST', body }, { 'Content-Type': 'application/json' });
};

export const patchAdmin = (id, body) => {
  return fetchFrom(`/v2/admin/${id}`, true, { method: 'PATCH', body }, { 'Content-Type': 'application/json' });
};

export const deleteAdmin = (id) => {
  return fetchFrom(`/v2/admin/${id}`, true, { method: 'DELETE' });
};

// Fishes
export const getFishes = async (query) => {
  if (query !== undefined) {
    const params = await generateQuery(query);
    return fetchFrom(`/v2/fish?${params}`, true);
  }

  return fetchFrom('/v2/fish', true);
};

export const getFishByID = (id) => {
  return fetchFrom(`/v2/fish/${id}`, true);
};

export const postFish = (body) => {
  return fetchFrom('/v2/fish', true, { method: 'POST', body });
};

export const patchFish = (id, body) => {
  return fetchFrom(`/v2/fish/${id}`, true, { method: 'PATCH', body });
};

export const deleteFish = (id) => {
  return fetchFrom(`/v2/fish/${id}`, true, { method: 'DELETE' });
};

// Fishermans
export const getFishermans = async (query) => {
  if (query !== undefined) {
    const params = await generateQuery(query);
    return fetchFrom(`/v2/fisherman?${params}`, true);
  }

  return fetchFrom('/v2/fisherman', true);
};

export const getFishermanByID = (id) => {
  return fetchFrom(`/v2/fisherman/${id}`, true);
};

export const postFisherman = (body) => {
  return fetchFrom('/v2/fisherman/add', true, { method: 'POST', body });
};

export const patchFisherman = (id, body) => {
  return fetchFrom(`/v2/fisherman/${id}/update`, true, { method: 'PATCH', body });
};

export const deleteFisherman = (id) => {
  return fetchFrom(`/v2/fisherman/${id}`, true, { method: 'DELETE' });
};

export const patchVerifyFisherman = (id, body) => {
  return fetchFrom(`/v2/fisherman/${id}/status/active`, true, { method: 'PATCH', body });
};

export const patchRejectFisherman = (id, body) => {
  return fetchFrom(`/v2/fisherman/${id}/status/rejected`, true, { method: 'PATCH', body });
};

// Fisherman Activities
export const getFishermanActivities = async (query) => {
  if (query !== undefined) {
    const params = await generateQuery(query);
    return fetchFrom(`/v2/fisherman-activity?${params}`, true);
  }

  return fetchFrom('/v2/fisherman-activity', true);
};

export const getFishermanActivityByID = (id) => {
  return fetchFrom(`/v2/fisherman-activity/${id}`, true);
};

export const postFishermanActivity = (body) => {
  return fetchFrom('/v2/fisherman-activity', true, { method: 'POST', body });
};

export const patchFishermanActivity = (id, body) => {
  return fetchFrom(`/v2/fisherman-activity/${id}`, true, { method: 'PATCH', body });
};

export const deleteFishermanActivity = (id) => {
  return fetchFrom(`/v2/fisherman-activity/${id}`, true, { method: 'DELETE' });
};

// Fisherman Report
export const getFishermanReports = async (query) => {
  if (query !== undefined) {
    const params = await generateQuery(query);
    return fetchFrom(`/v2/fisherman-report?${params}`, true);
  }

  return fetchFrom('/v2/fisherman-report', true);
};

export const getFishermanReportByID = (id) => {
  return fetchFrom(`/v2/fisherman-report/${id}`, true);
};

export const postFishermanReport = (body) => {
  return fetchFrom('/v2/fisherman-report', true, { method: 'POST', body });
};

export const patchFishermanReport = (id, body) => {
  return fetchFrom(`/v2/fisherman-report/${id}`, true, { method: 'PATCH', body });
};

export const deleteFishermanReport = (id) => {
  return fetchFrom(`/v2/fisherman-report/${id}`, true, { method: 'DELETE' });
};

export const verifyFishermanReport = (id, body) => {
  return fetchFrom(`/v2/fisherman-report/${id}/verify`, true, { method: 'PATCH', body });
};

// Equipments
export const getEquipments = async (query) => {
  if (query !== undefined) {
    const params = await generateQuery(query);
    return fetchFrom(`/v2/equipment?${params}`, true);
  }

  return fetchFrom('/v2/equipment', true);
};

export const getEquipmentByID = (id) => {
  return fetchFrom(`/v2/equipment/${id}`, true);
};

export const postEquipment = (body) => {
  return fetchFrom('/v2/equipment', true, { method: 'POST', body }, { 'Content-Type': 'application/json' });
};

export const patchEquipment = (id, body) => {
  return fetchFrom(`/v2/equipment/${id}`, true, { method: 'PATCH', body }, { 'Content-Type': 'application/json' });
};

export const deleteEquipment = (id) => {
  return fetchFrom(`/v2/equipment/${id}`, true, { method: 'DELETE' });
};

// Competitions
export const getCompetitions = async (query) => {
  if (query !== undefined) {
    const params = await generateQuery(query);
    return fetchFrom(`/v2/competition?${params}`, true);
  }

  return fetchFrom('/v2/competition', true);
};

export const getCompetitionByID = (id) => {
  return fetchFrom(`/v2/competition/${id}`, true);
};

export const postCompetition = (body) => {
  return fetchFrom('/v2/competition', true, { method: 'POST', body }, { 'Content-Type': 'application/json' });
};

export const patchCompetition = (id, body) => {
  return fetchFrom(`/v2/competition/${id}`, true, { method: 'PATCH', body }, { 'Content-Type': 'application/json' });
};

export const deleteCompetition = (id) => {
  return fetchFrom(`/v2/competition/${id}`, true, { method: 'DELETE' });
};

export const getCompetitionWinners = async (id, query) => {
  if (query !== undefined) {
    const params = await generateQuery(query);
    return fetchFrom(`/v2/competition/${id}/winner?${params}`, true);
  }

  return fetchFrom(`/v2/competition/${id}/winner`, true);
};

export const postCompetitionWinners = async (id, body) => {
  return fetchFrom(`/v2/competition/${id}/winner`, true, { method: 'POST', body }, { 'Content-Type': 'application/json' });
};

// Notifications
export const patchNotificationRead = async (id) => {
  return fetchFrom(`/v2/notification/${id}/read`, true, { method: 'PATCH' });
};

// Products
export const getProducts = async (query) => {
  if (query !== undefined) {
    const params = await generateQuery(query);
    return fetchFrom(`/v2/product?${params}`, true);
  }

  return fetchFrom('/v2/product', true);
};

export const getProductByID = (id) => {
  return fetchFrom(`/v2/product/${id}`, true);
};

export const postProduct = (body) => {
  return fetchFrom('/v2/product', true, { method: 'POST', body }, { 'Content-Type': 'application/json' });
};

export const patchProduct = (id, body) => {
  return fetchFrom(`/v2/product/${id}`, true, { method: 'PATCH', body }, { 'Content-Type': 'application/json' });
};

export const deleteProduct = (id) => {
  return fetchFrom(`/v2/product/${id}`, true, { method: 'DELETE' });
};

// Products Category
export const getProductCategories = async (query) => {
  if (query !== undefined) {
    const params = await generateQuery(query);
    return fetchFrom(`/v2/product-category?${params}`, true);
  }

  return fetchFrom('/v2/product-category', true);
};

export const getProductCategoryByID = (id) => {
  return fetchFrom(`/v2/product-category/${id}`, true);
};

export const postProductCategory = (body) => {
  return fetchFrom('/v2/product-category', true, { method: 'POST', body }, { 'Content-Type': 'application/json' });
};

export const patchProductCategory = (id, body) => {
  return fetchFrom(`/v2/product-category/${id}`, true, { method: 'PATCH', body }, { 'Content-Type': 'application/json' });
};

export const deleteProductCategory = (id) => {
  return fetchFrom(`/v2/product-category/${id}`, true, { method: 'DELETE' });
};

// Coordinates
export const getCoordinates = async (query) => {
  if (query !== undefined) {
    const params = await generateQuery(query);
    return fetchFrom(`/v2/fish-coordinate?${params}`, true);
  }

  return fetchFrom('/v2/fish-coordinate', true);
};

export const getCoordinateByID = (id) => {
  return fetchFrom(`/v2/fish-coordinate/${id}`, true);
};

export const postCoordinate = (body) => {
  return fetchFrom('/v2/fish-coordinate', true, { method: 'POST', body });
};

export const patchCoordinate = (id, body) => {
  return fetchFrom(`/v2/fish-coordinate/${id}`, true, { method: 'PATCH', body });
};

export const deleteCoordinate = (id) => {
  return fetchFrom(`/v2/fish-coordinate/${id}`, true, { method: 'DELETE' });
};

// Ports
export const getPorts = async (query) => {
  if (query !== undefined) {
    const params = await generateQuery(query);
    return fetchFrom(`/v2/port?${params}`, true);
  }

  return fetchFrom('/v2/port', true);
};

export const getPortByID = (id) => {
  return fetchFrom(`/v2/port/${id}`, true);
};

export const postPort = (body) => {
  return fetchFrom('/v2/port', true, { method: 'POST', body }, { 'Content-Type': 'application/json' });
};

export const patchPort = (id, body) => {
  return fetchFrom(`/v2/port/${id}`, true, { method: 'PATCH', body }, { 'Content-Type': 'application/json' });
};

export const deletePort = (id) => {
  return fetchFrom(`/v2/port/${id}`, true, { method: 'DELETE' });
};

// Location
export const getProvinces = async (query) => {
  if (query !== undefined) {
    const params = await generateQuery(query);
    return fetchFrom(`/v2/location?${params}`, true);
  }

  return fetchFrom('/v2/location', true);
};

export const getDistricts = async (query) => {
  if (query !== undefined) {
    const params = await generateQuery(query);
    return fetchFrom(`/v2/location?${params}`, true);
  }
  return fetchFrom('/v2/location', true);
};

export const getLocationByID = (id) => {
  return fetchFrom(`/v2/location/${id}`, true);
};

// Users
export const getUsers = async (query) => {
  if (query !== undefined) {
    const params = await generateQuery(query);
    return fetchFrom(`/v2/user?${params}`, true);
  }

  return fetchFrom('/v2/user', true);
};

export const getUserByID = (id) => {
  return fetchFrom(`/v2/user/${id}`, true);
};

export const postUser = (body) => {
  return fetchFrom('/v2/user/add', true, { method: 'POST', body }, { 'Content-Type': 'application/json' });
};

export const patchUser = (id, body) => {
  return fetchFrom(`/v2/user/${id}`, true, { method: 'PATCH', body }, { 'Content-Type': 'application/json' });
};

export const deleteUser = (id) => {
  return fetchFrom(`/v2/user/${id}`, true, { method: 'DELETE' });
};

// Spots
export const getSpots = async (query) => {
  if (query !== undefined) {
    const params = await generateQuery(query);
    return fetchFrom(`/v2/spot?${params}`, true);
  }

  return fetchFrom('/v2/spot', true);
};

export const getSpotByID = (id) => {
  return fetchFrom(`/v2/spot/${id}`, true);
};

export const postSpot = (body) => {
  return fetchFrom('/v2/spot', true, { method: 'POST', body }, { 'Content-Type': 'application/json' });
};

export const patchSpot = (id, body) => {
  return fetchFrom(`/v2/spot/${id}`, true, { method: 'PATCH', body }, { 'Content-Type': 'application/json' });
};

export const deleteSpot = (id) => {
  return fetchFrom(`/v2/spot/${id}`, true, { method: 'DELETE' });
};

// Upload Image
export const postImage = (body) => {
  return fetchFrom('/v2/upload/image', true, { method: 'POST', body });
};

// User Request Deletion

export const getUserRequestDeletion = async (query) => {
  if (query !== undefined) {
    const params = await generateQuery(query);
    return fetchFrom(`/v2/user-request-deletion?${params}`, true);
  }

  return fetchFrom('/v2/user-request-deletion', true);
};

export const deleteUserRequestDeletion = (id) => {
  return fetchFrom(`/v2/user-request-deletion/${id}`, true, { method: 'DELETE' });
};

/**
 * Call API using Axios
 * @param: endpoint, withToken, options, headers
 * @type: string, bool, object, object
 */
export async function fetchFrom(endpoint, withToken, options, head) {
  try {
    const headers = withToken ? { 'x-access-token': localStorage.getItem('accessToken'), ...head } : { ...head };
    const method = options ? options.method : 'GET';
    const data = options ? options.body : '';

    const response = await axios({
      url: `${process.env.REACT_APP_API_BASE_URL}${endpoint}`,
      method,
      data,
      headers,
    });

    return {
      ...response.data,
      status: response.status,
      statusText: response.statusText,
    };
  } catch (error) {
    if (error.response) {
      throw error.response;
    }

    throw error;
  }
}
