import React from 'react';
import { Layout } from 'antd';

export default function ContentComponent({ children }) {
  const { Content } = Layout;
  return (
    <>
      <Content>{children}</Content>
    </>
  );
}
