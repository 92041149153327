import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Login from './pages/login';

const AppUnaunthenticatedLayout = () => {
  return (
    <>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route render={({ location }) => <Redirect to={{ pathname: '/', state: { from: location } }} />} />
      </Switch>
    </>
  );
};

export default AppUnaunthenticatedLayout;
