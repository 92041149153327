import React from 'react';
import useSWR from 'swr';
import { useHistory } from 'react-router-dom';
import { notification } from 'antd';
import { useAuth } from '../../contexts/AuthContext';
import { View } from './view';
import { fetchFrom } from '../../api';

export default function HeaderComponent({ onCollapseButtonClicked }) {
  const { user, signOut } = useAuth();
  const history = useHistory();

  const { data, error } = useSWR(['/v2/notification?filters=sentToAdmin:=:true;isReaded:=:false', true], fetchFrom, { refreshInterval: 10000 });

  const handleSignout = async () => {
    try {
      await signOut();
      history.push('/');
    } catch (e) {
      notification.error({
        message: 'Error',
        description: 'An error has occured. Please try again.',
      });
    }
  };

  if (!data || error) {
    const emptyNotifications = {
      count: 0,
      results: [],
    };
    return <View user={user} onSignOut={handleSignout} onCollapseButtonClicked={onCollapseButtonClicked} notifications={emptyNotifications} />;
  }

  return <View user={user} onSignOut={handleSignout} onCollapseButtonClicked={onCollapseButtonClicked} notifications={data} />;
}
