import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import Icon, { ShoppingCartOutlined, EnvironmentOutlined, UserDeleteOutlined } from '@ant-design/icons';

import Logo from '../../static/images/Logo.png';
import { ReactComponent as AdminIcon } from '../../static/icons/icon_admin.svg';
import { ReactComponent as CompetitionIcon } from '../../static/icons/icon_competition.svg';
import { ReactComponent as CoordinateIcon } from '../../static/icons/icon_coordinate.svg';
import { ReactComponent as DashboardIcon } from '../../static/icons/icon_dashboard.svg';
import { ReactComponent as EquipmentIcon } from '../../static/icons/icon_equipment.svg';
import { ReactComponent as FishermanIcon } from '../../static/icons/icon_fisherman.svg';
import { ReactComponent as FishIcon } from '../../static/icons/icon_fish.svg';
import { ReactComponent as NewsIcon } from '../../static/icons/icon_news.svg';
import { ReactComponent as PortIcon } from '../../static/icons/icon_port.svg';
import { ReactComponent as UserIcon } from '../../static/icons/icon_user.svg';

export const View = ({ collapsed }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const { Sider } = Layout;
  const { Item } = Menu;

  // Set default active key
  const pathnameArray = [
    '/dashboard',
    '/admin',
    '/user',
    '/port',
    '/fish',
    '/coordinate',
    '/equipment',
    '/fisherman',
    '/fisherman/activity',
    '/fisherman/report',
    '/competition',
    '/news',
    '/user-request-deletion',
  ];
  const activeKeys = [];
  pathnameArray.forEach((item, index) => {
    if (pathname.includes(item)) {
      activeKeys.push((index + 1).toString());
    }
  });
  const defaultActiveKey = [];
  defaultActiveKey.push(activeKeys.pop());

  // Set default opened
  const defaultOpenKeys = pathname.includes('/fisherman') ? ['sub1'] : [];

  return (
    <>
      <Sider theme="light" breakpoint="lg" collapsed={collapsed} width={216}>
        <div style={{ textAlign: 'center', padding: 16, marginBottom: 16 }}>
          <img style={collapsed ? { maxWidth: '40px' } : { maxWidth: '80px' }} src={Logo} alt="FishGo Dasboard Logo" />
        </div>
        <Menu defaultSelectedKeys={defaultActiveKey} defaultOpenKeys={defaultOpenKeys} theme="light" mode="inline">
          <Item key="1" onClick={() => history.push('/dashboard')}>
            <Icon component={DashboardIcon} />
            <span className="nav-text">Dashboard</span>
          </Item>
          <Item key="2" onClick={() => history.push('/admin')}>
            <Icon component={AdminIcon} />
            <span className="nav-text">Admins</span>
          </Item>
          <Item key="3" onClick={() => history.push('/user')}>
            <Icon component={UserIcon} />
            <span className="nav-text">Users</span>
          </Item>
          <Item key="4" onClick={() => history.push('/port')}>
            <Icon component={PortIcon} />
            <span className="nav-text">Ports</span>
          </Item>
          <Item key="5" onClick={() => history.push('/fish')}>
            <Icon component={FishIcon} />
            <span className="nav-text">Fishes</span>
          </Item>
          <Item key="6" onClick={() => history.push('/coordinate')}>
            <Icon component={CoordinateIcon} />
            <span className="nav-text">Coordinates</span>
          </Item>
          <Item key="7" onClick={() => history.push('/equipment')}>
            <Icon component={EquipmentIcon} />
            <span className="nav-text">Equipments</span>
          </Item>
          <Menu.SubMenu
            key="sub1"
            title={
              <span>
                <Icon component={FishermanIcon} />
                <span className="nav-text">Fisherman</span>
              </span>
            }
          >
            <Menu.Item key="8" onClick={() => history.push('/fisherman')}>
              Candidates
            </Menu.Item>
            <Menu.Item key="9" onClick={() => history.push('/fisherman/activity-report')}>
              Activities & Reports
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item key="10" onClick={() => history.push('/spot')}>
            <Icon component={EnvironmentOutlined} className="svg-fill-amber" style={{ marginRight: 0 }} />
            <span className="nav-text">Spot</span>
          </Menu.Item>
          <Menu.Item key="11" onClick={() => history.push('/competition')}>
            <Icon component={CompetitionIcon} />
            <span className="nav-text">Competition</span>
          </Menu.Item>
          <Menu.Item key="12" onClick={() => history.push('/mart')}>
            <Icon component={ShoppingCartOutlined} className="svg-fill-amber" style={{ marginRight: 0 }} />
            <span className="nav-text">Mart</span>
          </Menu.Item>
          <Item key="13" onClick={() => history.push('/news')}>
            <Icon component={NewsIcon} />
            <span className="nav-text">News</span>
          </Item>
          <Item key="14" onClick={() => history.push('/user-request-deletion')}>
            <Icon component={UserDeleteOutlined} style={{ marginRight: 0, color: 'red' }} />
            <span className="nav-text">User Deletion</span>
          </Item>
        </Menu>
      </Sider>
    </>
  );
};
