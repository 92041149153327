import React from 'react';
import moment from 'moment';
import { Layout } from 'antd';

export default function FooterComponent() {
  const { Footer } = Layout;
  return (
    <>
      <Footer style={{ textAlign: 'center' }}>Copyright (C) FishGo {moment().format('YYYY')}</Footer>
    </>
  );
}
