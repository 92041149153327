import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import { Card, Row, Col, Typography, Avatar, notification } from 'antd';
import { patchNotificationRead } from '../../api';

export default function NotificationCard({ data, onNotificationClicked }) {
  const history = useHistory();
  const { id, title, isReaded, content, updatedAt, tag, sosSender } = data;
  const url = getURLFromNotificationType(tag, sosSender);

  const handleClick = async () => {
    try {
      if (!isReaded) {
        await patchNotificationRead(id);
      }

      onNotificationClicked();
      history.push(url);
    } catch (e) {
      notification.error({
        message: 'Internal Server Error',
        description: 'There is something wrong with the server. Contact the Administrator.',
      });
    }
  };

  return (
    <Card
      style={{ marginBottom: 16 }}
      className="cursor-pointer"
      title={
        <Row align="middle" justify="center">
          <Col flex="auto">
            <Typography.Paragraph ellipsis={{ rows: 1 }} style={{ marginBottom: 0 }}>
              {title}
            </Typography.Paragraph>
          </Col>
          {!isReaded && (
            <Col>
              <Avatar style={{ backgroundColor: '#df202e', marginTop: -8 }} shape="circle" size={12} />
            </Col>
          )}
        </Row>
      }
      onClick={handleClick}
    >
      <Row>
        <Col span={24}>
          <Typography.Paragraph ellipsis={{ rows: 3 }}>{content}</Typography.Paragraph>
        </Col>
        <Col span={24} className="font-size-12px">
          {moment(updatedAt).fromNow()}
        </Col>
      </Row>
    </Card>
  );
}

function getURLFromNotificationType(tag, sosSender) {
  switch (tag) {
    case 'db-competition-ongoing':
      return '/competition';
    case 'db-new-fisherman':
      return '/fisherman';
    case 'db-new-report':
      return '/fisherman/activity-report?active=2';
    case 'db-sos':
      return `/user/${sosSender.id}`;
    default:
      return '/';
  }
}
