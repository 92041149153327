import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Icon, { MenuOutlined, ExportOutlined } from '@ant-design/icons';
import { Layout, Row, Col, Avatar, Dropdown, Menu, Button, Badge, Drawer, notification, Empty } from 'antd';
import NotificationCard from '../NotificationCard';
import toCapitalCase from '../../utils/toCapitalCase';

import { ReactComponent as NotificationIcon } from '../../static/icons/icon_notification.svg';
import { patchNotificationRead } from '../../api';

export const View = ({ user, notifications, onSignOut, onCollapseButtonClicked }) => {
  const { Header } = Layout;
  const { pathname } = useLocation();

  // Notification Related
  const { count, results } = notifications;
  const [notificationItems, setNotificationItems] = useState(results);
  const [notificationCount, setNotificationCount] = useState(count);
  const [isNotificationOpened, setIsNotificationOpened] = useState(false);
  const handleNotificationClicked = (notificationID) => {
    // Update notification items
    setNotificationItems((prevState) => prevState.filter((item) => item.id !== notificationID));
    // Update notification count
    setNotificationCount((prevState) => prevState - 1);
    // Close the notification drawer
    setIsNotificationOpened(false);
  };
  const handleClearAllNotification = async () => {
    try {
      // Update all notification to be read
      await notificationItems.forEach((item) => {
        patchNotificationRead(item.id);
      });

      // Clear the notifications
      setNotificationItems([]);
      setNotificationCount(0);
    } catch (e) {
      notification.error({
        message: 'Internal Server Error',
        description: 'There is something wrong with the server. Contact the Administrator.',
      });
    }
  };

  useEffect(() => {
    setNotificationItems(results);
    setNotificationCount(count);
  }, [results, count]);

  // Location pathname related
  const pathnameArray = [
    '/dashboard',
    '/admin',
    '/user',
    '/port',
    '/fish',
    '/coordinate',
    '/equipment',
    '/fisherman',
    '/fisherman/activity',
    '/fisherman/report',
    '/competition',
    '/mart',
    '/news',
  ];
  let activePage = '';
  pathnameArray.forEach((item) => {
    if (pathname.includes(item)) {
      activePage = toCapitalCase(item.split('/').join(' '));
    }
  });

  return (
    <>
      <Header style={{ background: '#2979ff', color: 'white' }}>
        <Row align="middle" justify="space-between">
          <Col flex="auto">
            <MenuOutlined onClick={onCollapseButtonClicked} style={{ marginRight: 16 }} />
            <span className="font-weight-600">{activePage}</span>
          </Col>
          <Col style={{ textAlign: 'right' }}>
            <Menu theme="dark" mode="horizontal" className="nav-right-menu">
              <Menu.Item onClick={() => setIsNotificationOpened(true)}>
                <Badge count={notificationCount}>
                  <Icon component={NotificationIcon} style={{ fontSize: 24, margin: 0 }} />
                </Badge>
              </Menu.Item>
              <Menu.Item>
                <Dropdown
                  placement="bottomCenter"
                  overlay={
                    <Menu mode="vertical">
                      <Menu.Item>
                        <Button type="text" icon={<ExportOutlined />} onClick={onSignOut}>
                          Logout
                        </Button>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button type="default" size="large" style={{ marginTop: 11, borderRadius: 32 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar className="cursor-pointer" size={20} style={{ backgroundColor: '#f56a00', marginRight: 8 }}>
                        {toCapitalCase(user.username.substr(0, 1))}
                      </Avatar>
                      <span className="cursor-pointer font-weight-500">{toCapitalCase(user.username)}</span>
                    </div>
                  </Button>
                </Dropdown>
              </Menu.Item>
            </Menu>
          </Col>
        </Row>
      </Header>
      <Drawer title="Notifications" visible={isNotificationOpened} width={420} onClose={() => setIsNotificationOpened(false)} placement="left">
        <Row gutter={[0, 16]}>
          {notificationCount > 0 && (
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button type="default" onClick={handleClearAllNotification}>
                Clear All
              </Button>
            </Col>
          )}
          <Col span={24}>
            {notificationCount > 0 ? (
              notificationItems.map((item) => {
                return <NotificationCard data={item} onNotificationClicked={() => handleNotificationClicked(item.id)} key={item.id} />;
              })
            ) : (
              <Empty description="There are no notifications yet." />
            )}
          </Col>
        </Row>
      </Drawer>
    </>
  );
};
