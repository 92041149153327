import React, { createContext, useState, useContext } from 'react';
import jwtDecode from 'jwt-decode';
import { postAdminLogin } from '../api';

const AuthContext = createContext();

function useAuthProvider() {
  const accessToken = localStorage.getItem('accessToken');
  const username = localStorage.getItem('username');
  const [user, setUser] = useState({
    accessToken,
    username,
  });

  const signIn = async (values) => {
    try {
      const result = await postAdminLogin(values);
      const { token } = result;
      if (token) {
        const jwtData = jwtDecode(token);
        localStorage.setItem('accessToken', token);
        localStorage.setItem('username', jwtData.user.username);

        setUser({
          accessToken: token,
          username: jwtData.user.username,
        });

        return {
          success: true,
          user,
        };
      }
    } catch (e) {
      if (!e.status) {
        throw Error(e.message);
      } else {
        if (e.status >= 500) {
          throw Error(e.message);
        }

        return {
          success: false,
          error: e.message,
        };
      }
    }
  };

  const signOut = async () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('username', null);
    setUser(false);
  };

  return {
    user,
    signIn,
    signOut,
  };
}

export const useAuth = () => {
  return useContext(AuthContext);
};

export default function AuthProvider({ children }) {
  const auth = useAuthProvider();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}
