import React, { Suspense, useEffect, useState, lazy } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Layout, notification } from 'antd';
import Sider from './components/Sider';
import HeaderComponent from './components/Header';
import ContentComponent from './components/Content';
import FooterComponent from './components/Footer';
import { BigLoader } from './components/Loader';
import { authenticate } from './api';
import { useAuth } from './contexts/AuthContext';

// Pages
const DashboardPage = lazy(() => import('./pages/dashboard'));
// Admin
const AdminIndexPage = lazy(() => import('./pages/admin/index'));
// User
const UserIndexPage = lazy(() => import('./pages/user/index'));
const UserShowPage = lazy(() => import('./pages/user/show'));
const UserRequestDeletionIndexPage = lazy(() => import('./pages/user-request-deletion/index'));
// Competition
const CompetitionIndexPage = lazy(() => import('./pages/competition/index'));
const CompetitionCreatePage = lazy(() => import('./pages/competition/create'));
const CompetitionShowPage = lazy(() => import('./pages/competition/show'));
const CompetitionEditPage = lazy(() => import('./pages/competition/update'));
const CompetitionDeletePage = lazy(() => import('./pages/competition/delete'));
const CompetitionWinnerPage = lazy(() => import('./pages/competition/winner'));
// Coordinate
const CoordinateIndexPage = lazy(() => import('./pages/coordinate/index'));
const CoordinateCreatePage = lazy(() => import('./pages/coordinate/create'));
const CoordinateShowPage = lazy(() => import('./pages/coordinate/show'));
const CoordinateEditPage = lazy(() => import('./pages/coordinate/update'));
const CoordinateDeletePage = lazy(() => import('./pages/coordinate/delete'));
// Fish
const FishIndexPage = lazy(() => import('./pages/fish/index'));
const FishCreatePage = lazy(() => import('./pages/fish/create'));
const FishShowPage = lazy(() => import('./pages/fish/show'));
const FishEditPage = lazy(() => import('./pages/fish/update'));
const FishDeletePage = lazy(() => import('./pages/fish/delete'));
// Fisherman
const FishermanIndexPage = lazy(() => import('./pages/fisherman/index'));
const FishermanShowPage = lazy(() => import('./pages/fisherman/show'));
// Fisherman Activity & Report
const FishermanActivityReport = lazy(() => import('./pages/fisherman-activity-report/index'));
const FishermanActivityReportReportShow = lazy(() => import('./pages/fisherman-activity-report/tabs/fisherman-report/show'));
// Equipment
const EquipmentIndexPage = lazy(() => import('./pages/equipment/index'));
const EquipmentCreatePage = lazy(() => import('./pages/equipment/create'));
const EquipmentShowPage = lazy(() => import('./pages/equipment/show'));
const EquipmentEditPage = lazy(() => import('./pages/equipment/update'));
const EquipmentDeletePage = lazy(() => import('./pages/equipment/delete'));
// Spot
const SpotIndexPage = lazy(() => import('./pages/spot/index'));
const SpotShowPage = lazy(() => import('./pages/spot/show'));
const SpotDeletePage = lazy(() => import('./pages/spot/delete'));
// Port
const PortIndexPage = lazy(() => import('./pages/port/index'));
const PortCreatePage = lazy(() => import('./pages/port/create'));
const PortShowPage = lazy(() => import('./pages/port/show'));
const PortEditPage = lazy(() => import('./pages/port/update'));
const PortDeletePage = lazy(() => import('./pages/port/delete'));
// Mart
const MartIndexPage = lazy(() => import('./pages/mart/index'));
// Miscellanous
const NotFoundPage = lazy(() => import('./pages/NotFound'));
const NotImplementedPage = lazy(() => import('./pages/NotImplemented'));

const AppAuthenticatedLayout = () => {
  const location = useLocation();
  const { user, signOut } = useAuth();
  useEffect(() => {
    if (user.accessToken) {
      authenticate()
        .then(async () => {
          // Do nothing. User is authenticated.
        })
        .catch(async (error) => {
          if (error.status === 401) {
            notification.info({
              message: 'Session Expired',
              description: 'Your session has expired. Please log in.',
            });
            await signOut();
          } else {
            notification.error({
              message: 'Internal Server Error',
              description: 'There is something wrong with the server. Contact the Administrator.',
            });
          }
        });
    }
  }, [location, user, signOut]);

  // Sider
  const [collapsed, setCollapsed] = useState(false);
  const handleCollapsed = () => {
    const currentCollapsed = collapsed;
    setCollapsed(!currentCollapsed);
  };

  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        <Suspense fallback={<BigLoader />}>
          <Sider collapsed={collapsed} />
          <Layout>
            <HeaderComponent onCollapseButtonClicked={handleCollapsed} />
            <ContentComponent>
              <Switch>
                <Route path="/" render={() => <Redirect to={{ pathname: '/dashboard', state: { from: '/' } }} />} exact={true} />
                <Route path="/dashboard" exact component={DashboardPage} />
                {/* Admin Routes */}
                <Route path="/admin" exact component={AdminIndexPage} />
                {/* User Routes */}
                <Route path="/user" exact component={UserIndexPage} />
                <Route path="/user/:id" exact component={UserShowPage} />
                {/* Competition Routes */}
                <Route path="/competition" exact component={CompetitionIndexPage} />
                <Route path="/competition/new" exact component={CompetitionCreatePage} />
                <Route path="/competition/:id" exact component={CompetitionShowPage} />
                <Route path="/competition/edit/:id" component={CompetitionEditPage} />
                <Route path="/competition/delete/:id" component={CompetitionDeletePage} />
                <Route path="/competition/:id/winner" exact component={CompetitionWinnerPage} />
                {/* Coordinate Routes */}
                <Route path="/coordinate" exact component={CoordinateIndexPage} />
                <Route path="/coordinate/new" exact component={CoordinateCreatePage} />
                <Route path="/coordinate/:id" exact component={CoordinateShowPage} />
                <Route path="/coordinate/edit/:id" component={CoordinateEditPage} />
                <Route path="/coordinate/delete/:id" component={CoordinateDeletePage} />
                {/* Fish Routes */}
                <Route path="/fish" exact component={FishIndexPage} />
                <Route path="/fish/new" exact component={FishCreatePage} />
                <Route path="/fish/:id" exact component={FishShowPage} />
                <Route path="/fish/edit/:id" component={FishEditPage} />
                <Route path="/fish/delete/:id" component={FishDeletePage} />
                {/* Fisherman Activity & Report */}
                <Route path="/fisherman/activity-report" exact component={FishermanActivityReport} />
                <Route path="/fisherman/activity-report/report/:id" exact component={FishermanActivityReportReportShow} />
                {/* Fisherman Routes */}
                <Route path="/fisherman" exact component={FishermanIndexPage} />
                <Route path="/fisherman/:id" exact component={FishermanShowPage} />
                {/* Port Routes */}
                <Route path="/port" exact component={PortIndexPage} />
                <Route path="/port/new" exact component={PortCreatePage} />
                <Route path="/port/:id" exact component={PortShowPage} />
                <Route path="/port/edit/:id" component={PortEditPage} />
                <Route path="/port/delete/:id" component={PortDeletePage} />
                {/* Equipment Routes */}
                <Route path="/equipment" exact component={EquipmentIndexPage} />
                <Route path="/equipment/new" exact component={EquipmentCreatePage} />
                <Route path="/equipment/:id" exact component={EquipmentShowPage} />
                <Route path="/equipment/edit/:id" component={EquipmentEditPage} />
                <Route path="/equipment/delete/:id" component={EquipmentDeletePage} />
                {/* Spots Routes */}
                <Route path="/spot" exact component={SpotIndexPage} />
                <Route path="/spot/:id" exact component={SpotShowPage} />
                <Route path="/spot/delete/:id" component={SpotDeletePage} />
                {/* Commerece Routes */}
                <Route path="/mart" exact component={MartIndexPage} />
                {/* News Routes */}
                <Route path="/news" exact component={NotImplementedPage} />
                {/* User Request Deletion Routes */}
                <Route path="/user-request-deletion" exact component={UserRequestDeletionIndexPage} />
                {/* Not Found Route */}
                <Route path="*" component={NotFoundPage} />
              </Switch>
            </ContentComponent>
            <FooterComponent />
          </Layout>
        </Suspense>
      </Layout>
    </>
  );
};

export default AppAuthenticatedLayout;
