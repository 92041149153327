import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Spin } from 'antd';
import AuthProvider, { useAuth } from './contexts/AuthContext';

import './styles/App.scss';
import AppUnaunthenticatedLayout from './AppUnauthenticated';
import AppAuthenticatedLayout from './AppAuthenticated';

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <BrowserRouter>
          <AppLayout />
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

function AppLayout() {
  const { user } = useAuth();

  if (!user.accessToken) {
    return (
      <Suspense fallback={<Spin />}>
        <AppUnaunthenticatedLayout />
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<Spin />}>
      <AppAuthenticatedLayout />
    </Suspense>
  );
}

export default App;
